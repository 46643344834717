import { css } from '@emotion/react' 
import { Component } from 'react'
// import PropTypes from 'prop-types'
import {
    CardElevation,
    ButtonDegrade,
    Oferta
} from '@findep/mf-landings-core';
import { 
    Typography,
    Container
} from '@material-ui/core';
import CarouselImg from '../img/CarouselImg.compilable'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import { navigate } from "../../helpers/queryNavigate"
// import {AttachMoney} from '@material-ui/icons';

import formatNumber from '../../helpers/formatNumber'

import { CataloguesService } from '../../services/LandingV4';

const imageContainer = css`
    display: flex;
    position: center;
    height: 250px;
`
// preOferta.oferta.amount y preOferta.oferta.payment

const catalogues = new CataloguesService();
export default class NumRastreo extends Component {
    constructor(props){
        super(props)
        this.state = {
            tdatos: !((typeof window !== 'undefined') && sessionStorage.getItem('tdatos') === null) ? (typeof window !== 'undefined') ? JSON.parse(sessionStorage.getItem('tdatos')) : '' : '',
            flujo: !((typeof window !== 'undefined') && sessionStorage.getItem('flujo') === null) ? (typeof window !== 'undefined') ? JSON.parse(sessionStorage.getItem('flujo')) : '' : '',
            phone: '',
            monto: '',
            pago: ''
        }
        const {flujo} = this.state
        this.state = {...this.state,  props: [
            {icono: ArrowForwardIcon, titulo: 'Monto:', texto: `${formatNumber(flujo ? flujo.preOferta ? flujo.preOferta.oferta.amount : 0 : 0)}` },
            { icono: ArrowForwardIcon, titulo: 'Pago:', texto: `${formatNumber(flujo ? flujo.preOferta ? flujo.preOferta.oferta.payment : '0 ' : '0 ')}` },
          ]}
    }

    

    componentDidMount() {
        catalogues.getPrescoreNip(this.state.flujo.creditApplicationId)
        .then((response) => {
            this.setState({ phone: response.data.mobileNumber })
        })
        .catch(e => {
            console.log(e)
        })

        catalogues.getCongratulations(this.state.flujo.creditApplicationId)
        .then((response) => {
            this.setState({ 
                monto: response.data.amount,
                pago: response.data.estimatedPayment
            })
        })
        .catch(e => {
            console.log(e)
        })
    }

    async nextPage() {
        navigate(this.props.pageContext?.next?.path)
    }

    render() {
        const {pageContext} = this.props
        const {flujo, monto, pago} = this.state

        const datosMontoPago = [
            {icono: ArrowForwardIcon, titulo: 'Monto:', texto: `${formatNumber(flujo?.preOferta?.oferta?.amount || monto)}` },
            { icono: ArrowForwardIcon, titulo: 'Pago:', texto: `${formatNumber(flujo?.preOferta?.oferta?.payment || pago)}` },
        ];
       // console.log(pageContext)
        return (
            <Container align="center">
                <br />
                <Oferta datos={datosMontoPago} colorOne="#FF9F01" colorTwo="#FA4616"/>
                <Typography align="center" color='primary' font-size="3rem" fontWeight='fontWeightBold'>
                    <h2>Número de rastreo</h2>
                </Typography>
                <CardElevation>
                    <Typography align="left">
                        ¿No tienes a la mano algún documento necesario? ¡No te preocupes! Puedes completar tu proceso de crédito en cualquier momento ingresando tu número de rastreo:
                    </Typography>
                    <Typography align="center" color='primary' font-size="4rem" fontWeight='fontWeightBold'>
                        <h2>No. {this.state.tdatos?.telefono || this.state.phone}</h2>
                    </Typography>
                    
                </CardElevation>
                <br />
                <ButtonDegrade 
                    id="numero-rastreo-obtener-prestamo-button"
                    icon={ArrowForwardIcon}
                    textButton="OBTENER MI CRÉDITO"
                    onClick={
                        () => {
                            this.nextPage()
                        }
                    }
                />
                <br />
                <br />
                <CardElevation>
                     <CarouselImg customCss={imageContainer} filename="3.svg" company={pageContext.company} /> 
                    
                </CardElevation>
                <br/>
            </Container>
        )
    }
}
